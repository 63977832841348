import React from "react";
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes, useLocation } from 'react-router-dom'
import Portfolio from "./pages/Portfolio";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import { AnimatePresence } from "framer-motion";
import { observer } from 'mobx-react-lite'
import { ModalVideo } from "./components/shared/ModalVideo";
import About from "./pages/About";
import { ModalReel } from "./components/shared/ModalReel";
import { Alert } from "./components/shared/Alert";
import meta from './store/meta.js'

const App = observer(() => {
  const location = useLocation();

  return (
    <>
      <ModalVideo />
      <ModalReel />
      <Alert show={meta.showAlert} message={meta.alertMessage} />
      <ScrollToTop>
        <Header />
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="/work" element={<Portfolio />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </AnimatePresence>
        <Footer />
      </ScrollToTop>
    </>
  );
})

export default App;
