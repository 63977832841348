import React, { useEffect, useRef } from 'react'
import '../assets/menu.css'
import { useLocation, useNavigate } from 'react-router-dom'

import close from '../images/close.svg'
import vimeo from '../images/vimeo.svg'
import instagram from '../images/instagram.svg'
import contactBtn from "../images/contact_black.png"

export const Menu = ({ active, setActive }) => {
  const navigate = useNavigate();
  const location = useLocation()
  const modalRef = useRef(null);

  const closeModal = () => {
    modalRef.current.style.transition = '0.4s all'
    setTimeout(() => {
      setActive(false)
    }, 0)
  }

  const updateModalHeight = () => {
    if (modalRef.current) {
      modalRef.current.style.height = `${window.innerHeight}px`
    }
  }

  const toPage = (name) => {
    if (name) {
      navigate(name)
      closeModal()
    }
  }

  const toContact = () => {
    closeModal()
    window.scrollTo(0, document.body.scrollHeight)
  }

  useEffect(() => {
    active ? document.body.style.overflow = 'hidden' : document.body.style.overflow = ''

    if (active) {
      setTimeout(() => {
        modalRef.current.style.transition = 'unset'
      }, 400)
      updateModalHeight()
      window.addEventListener('resize', updateModalHeight)
    }

    return () => {
      window.removeEventListener('resize', updateModalHeight)
    };
  }, [active])


  return (
    <div className={active ? 'menu active' : 'menu'} onClick={() => closeModal()}>
      <div ref={modalRef} className={active ? 'modal_content active container' : 'modal_content container'} onClick={e => e.stopPropagation()}>
        <div className='top-container'>
          <span className='menu-item'>MENU</span>
          <span className='closeButton' onClick={() => closeModal()}><img src={close} alt='close' /></span>
        </div>
        <div className='mid-container'>
          <div onClick={() => toPage(`/`)} className={location.pathname === '/' ? 'item active' : 'item'}>HOME</div>
          <div onClick={() => toPage(`/work`)} className={location.pathname === '/work' ? 'item active' : 'item'}>WORKS</div>
          <div onClick={() => toPage(`/about`)} className={location.pathname === '/about' ? 'item active' : 'item'}>ABOUT</div>
        </div>
        <div className='bot-container'>
          <button onClick={() => toContact()}><img src={contactBtn} alt='contactBtn' className='connectBtn' /></button>
          <div className='networks-media-container'>
            <img className='networks-media' src={vimeo} alt='vimeo' onClick={() => window.open('https://vimeo.com/user125657309', '_blank')} />
            <img className='networks-media' src={instagram} alt='vimeo' onClick={() => window.open('https://www.instagram.com/himia.digital', '_blank')} />
          </div>
        </div>
      </div>
    </div>
  )
}
