import React, { useEffect } from 'react'
import '../assets/home.css'

import mainMob from "../images/home/PILL_INTRO.gif"
import titleMob from "../images/home/title-mob.png"
import titleDesk from "../images/home/himia_is_a.png"
import { motion } from 'framer-motion'
import { ShowReel } from '../components/home/ShowReel'
import { RecentWorks } from '../components/shared/RecentWorks'

const Home = () => {
  useEffect(() => {
    document.title = "HIMIA"
    window.scrollTo({ top: 0, behavior: "instant" })
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className='home'>
        <div className='container'>
          <div className="mainImg">
            <img src={mainMob} alt='mainImg' />
          </div>
          <div className="row home-title">
            <div className="title">
              <img className="title" src={titleDesk} alt='titleDesk' />
            </div>
            <div className="subtitle">
              <h2>DELIVERING CUTTING EDGE POST-PRODUCTION SERVICES FOR MUSIC VIDEOS, COMMERCIALS, AND DIGITAL CONTENT, POWERED BY OUR TEAM OF VISIONARY TALENTS</h2>
            </div>
          </div>
        </div>
        <ShowReel />
        <RecentWorks />
      </div>
    </motion.div>
  )
}

export default Home
