import React, { useState, useEffect } from 'react'
import '../assets/footer.css'
import copyright from '../images/footer/copyright.svg'
import titleDesk from '../images/footer/get_in_touch_desktop.png'
import titleMob from '../images/footer/get_in_touch_mobile.png'
import toTop from '../images/footer/to-top.svg'
import vimeo from '../images/vimeo-whtite.svg'
import instagram from '../images/instagram-white.svg'
import sendBtn from '../images/send.png'
import validationSchema from '../services/validation/schemas/contact.json'
import { useValidForm } from '../services/validation/validForm'
import meta from '../store/meta.js'

const Footer = () => {
  const validForm = useValidForm(validationSchema)
  const [validationErrors, setValidationErrors] = useState(validForm.initialState())
  const [isSendingFailed, setIsSendingFailed] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 767);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 767);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const submit = async (e) => {
    e.preventDefault()

    setIsSendingFailed(false)
    if (isLoading) return

    setValidationErrors(validForm.validateFields({ name, email, description }))
    if (validForm.isValid()) {
      setIsLoading(true)
      const response = await fetch('/server/sendMail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          email: email,
          description: description
        }),
      });
      try {
        const result = await response.json()
        if (result?.status === 'success') {
          setEmail('')
          setName('')
          setDescription('')
          meta.showAlertWindow('Your message has been successfully sent! 🎉')
        } else {
          setIsSendingFailed(true)
        }
      } catch (e) {
        setIsSendingFailed(true)
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <div className='footer' >
      <div className='container'>
        <div className="row">
          <div className="footer-wrapper">
            <div className="col-12 col-md-3 first-cont">
              {isMobile ? (
                <img className="footer-title-img" src={titleMob} alt="titleMob" />
              ) : (
                <img className="footer-title-img" src={titleDesk} alt="titleDesk" />
              )}
            </div>
            <div className="col-12  col-md-4">
              <form>
                <input
                  className={validationErrors.name.length ? 'red-border' : ''}
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className='validation-error'>{validationErrors?.name[0]}</p>
                <input
                  className={validationErrors.email.length ? 'red-border' : ''}
                  type="text"
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className='validation-error' >{validationErrors?.email[0]}</p>
                <input
                  className={validationErrors.description.length ? 'red-border' : ''}
                  type='text'
                  placeholder='Your message'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <p className='validation-error'>{validationErrors?.description[0]}</p>
                <div className='submit-cont'>
                  <button
                    onClick={(e) => submit(e)}
                    className={isLoading ? 'send-button button--loading' : 'send-button'}>
                    <span className="button__text"><img src={sendBtn} alt="send" /></span>
                  </button>
                  {isSendingFailed && <span className='submitting-error'>Something went wrong. Please try again.</span>}
                </div>
              </form>
            </div>
            <div className="col-12  col-md-5 third-cont">
              <div className='social-container'>
                <div className="social-wrapper">
                  <div className='social-contacts'>
                    <p className='social-label'>For all inquiries:</p>
                    <div className="social-contacts-wrapper">
                      <a className='social-contacts-link' href="mailto:hello@himia.digital"><span>hello@himia.digital</span></a>
                      <span>+34 678 694 762</span>
                    </div>
                  </div>
                  <div className='social-contacts'>
                    <p className='social-label'>Connect:</p>
                    <div className='networks'>
                      <img src={vimeo} alt='vimeo' onClick={() => window.open('https://vimeo.com/user125657309', '_blank')} />
                      <img src={instagram} alt='vimeo' onClick={() => window.open('https://www.instagram.com/himia.digital', '_blank')} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="fourth-cont">
        <div className='copyright'>
          <img src={copyright} alt='vimeo' />
          <span>Copyright 2024 HIMIA.</span>
        </div>
        <div className='to-top' onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
          <span>TO TOP</span>
          <img src={toTop} alt='vimeo' />
        </div>
      </div>
    </div>
  )
}

export default Footer
