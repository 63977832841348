import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from './Menu'
import '../assets/header.css'
//import { useNavigate } from 'react-router-dom'
import vimeo from '../images/vimeo-whtite.svg'
import instagram from '../images/instagram-white.svg'
import H from '../images/H-rotated.gif'
import IMIA from "../images/logo_imia.png"
import dots from "../images/dots_vertical.png"
import contactBtn from "../images/contact_white.png"

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  //const navigate = useNavigate();

  return (
    <>
      <Menu active={isMenuOpen} setActive={(state) => setMenuOpen(state)} />
      <div className='header'>
        <div className='header-container'>
          <div className='header-cont'>
            <div className='left-cont'>
              <Link to="/" className='logo'>
                <img src={H} alt='h' className='logo-gif' />
                <img src={IMIA} alt='imia' className='logo-name' />
              </Link>
            </div>
            <div className='mid-cont' >
              <img src={vimeo} alt='vimeo' onClick={() => window.open('https://vimeo.com/user125657309', '_blank')} />
              <img src={instagram} alt='vimeo' onClick={() => window.open('https://www.instagram.com/himia.digital', '_blank')} />
            </div>
            <div className='right-cont'>
              <button onClick={() => window.scrollTo(0, document.body.scrollHeight)} className='d-none d-sm-inline-block'>
                <img src={contactBtn} alt="contact" />
              </button>
              <button className="burger-btn" onClick={() => setMenuOpen(true)}>
                <img src={dots} alt='menu' className="burger" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
